import {
  add,
  eachMonthOfInterval,
  format,
  max,
  startOfMonth,
  sub,
} from "date-fns"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import AmountInputField from "src/main/Contracts/ContractsForm/shared/AmountInputField"
import AmountTypeSelector from "src/main/Contracts/ContractsForm/shared/AmountTypeSelector"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"
import Table from "src/components/Table"

import {
  validateDiscountAmount,
  validateDiscountMonthRange,
  validateName,
} from "./validators"

const DiscountsModal = ({
  onSave,
  isOpen,
  onClose,
  selectedDiscount,
  hidePlaceholders = false,
  billingCycle = "",
  contractStartDate = "",
  contractCompleted = false,
}) => {
  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: selectedDiscount ?? {
      name: "",
      amountType: "percent",
      amount: "",
      ...(billingCycle === "month_to_month" && { discountType: "every" }),
    },
  })

  const oneYearAgo = sub(new Date(), { months: 12 })
  const earliestMonthOption = max([
    startOfMonth(contractStartDate),
    startOfMonth(oneYearAgo),
  ])
  const selectedAmountType = watch("amountType")
  const selectedDiscountType = watch("discountType")
  const discountEndMonth = watch("discountEndMonth")
  const defaultStartValue = contractStartDate
    ? format(earliestMonthOption, "yyyy-MM-dd")
    : null

  useEffect(() => {
    if (billingCycle === "month_to_month" && !!contractStartDate) {
      if (selectedDiscountType === "month") {
        setValue(
          "discountMonth",
          selectedDiscount?.discountMonth || defaultStartValue
        )
      } else if (selectedDiscountType === "range") {
        setValue(
          "discountStartMonth",
          selectedDiscount?.discountStartMonth || defaultStartValue
        )
        setValue("discountEndMonth", selectedDiscount?.discountEndMonth || "")
      }
    }
  }, [
    selectedDiscountType,
    billingCycle,
    contractStartDate,
    selectedDiscount,
    defaultStartValue,
    setValue,
  ])

  const onAddClick = (data) => {
    onSave(data)
    onClose()
  }

  // the earliest month option available should be the later of the contract
  // start month or 1 year ago
  // the latest month option available should be the later of 1 year from
  // the contract start date or 1 year from the current month
  const renderMonthOptions = () => {
    const endBasis = max([
      startOfMonth(new Date()),
      startOfMonth(contractStartDate),
    ])
    const latestMonthOption = add(endBasis, { months: 11 })
    const monthOptions = eachMonthOfInterval({
      start: earliestMonthOption,
      end: latestMonthOption,
    })

    return monthOptions.map((date) => {
      return (
        <option
          key={format(date, "yyyy-MM-dd")}
          value={format(date, "yyyy-MM-dd")}
        >
          {format(date, "MMMM yyyy")}
        </option>
      )
    })
  }

  const renderSingleMonthInput = () => {
    return (
      <>
        <Form.Label htmlFor="discount-options-month">Month</Form.Label>
        <Form.Select
          id="discount-options-month"
          {...register("discountMonth", {
            required: true,
          })}
          hasErrors={Boolean(errors.discountMonth)}
        >
          {renderMonthOptions()}
        </Form.Select>
      </>
    )
  }

  const renderMonthRangeInputs = () => {
    return (
      <>
        <div className="flex flex-row items-center">
          <i
            data-testid="alert-banner-icon"
            className="icon icon-md-info mr-2 text-lg font-bold text-yellow-700"
          />
          <span>
            Discount will be applied to all months during the specified period.
          </span>
        </div>
        <div className="flex flex-row gap-6">
          <div className="w-1/2">
            <Form.Label htmlFor="discount-options-range-start">
              Beginning month
            </Form.Label>
            <Form.Select
              id="discount-options-range-start"
              {...register("discountStartMonth", {
                validate: validateDiscountMonthRange(discountEndMonth),
              })}
              hasErrors={Boolean(errors.discountStartMonth)}
            >
              {renderMonthOptions()}
            </Form.Select>
          </div>
          <div className="w-1/2">
            <Form.Label htmlFor="discount-options-range-end">
              Ending month
            </Form.Label>
            <Form.Select
              id="discount-options-range-end"
              {...register("discountEndMonth")}
              hasErrors={Boolean(errors.discountStartMonth)}
            >
              {renderMonthOptions()}
            </Form.Select>
          </div>
        </div>
      </>
    )
  }

  const renderPastAndPaidMonthsAlert = () => {
    return (
      <div className="flex flex-row items-center">
        <i
          data-testid="alert-banner-icon"
          className="icon icon-md-info mr-2 text-lg font-bold text-yellow-700"
        />
        <span>
          Discount will be applied to all months including past and paid-for
          months
        </span>
      </div>
    )
  }

  const renderMonthToMonthOptionFields = () => {
    return (
      <>
        <div className="flex flex-row gap-6">
          <div className="w-1/2">
            <Form.Label htmlFor="discount-options-type">
              Discount options
            </Form.Label>
            <Form.Select
              id="discount-options-type"
              {...register("discountType", {
                required: true,
              })}
              onChange={(e) => {
                setValue("discountType", e.target.value)
                clearErrors([
                  "discountMonth",
                  "discountStartMonth",
                  "discountEndMonth",
                ])
              }}
              hasErrors={Boolean(errors.discountType)}
            >
              <option key="every" value="every">
                Apply to all months
              </option>
              <option key="month" value="month">
                Apply to a specific month
              </option>
              <option key="range" value="range">
                Apply to a monthly range
              </option>
            </Form.Select>
          </div>
          <div className="w-1/2">
            {selectedDiscountType === "month" && renderSingleMonthInput()}
          </div>
        </div>
        {selectedDiscountType === "range" && renderMonthRangeInputs()}
        {selectedDiscountType === "every" &&
          contractCompleted &&
          renderPastAndPaidMonthsAlert()}
      </>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      afterLeave={reset}
      size="mediumFixed"
    >
      <Modal.Header>
        <div className="text-lg font-semibold">
          {selectedDiscount ? "Edit" : "Add"} discount
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="flex flex-col gap-6">
            <Table autoColumnWidth fullHeight>
              <Table.Head>
                <Table.Head.Row>
                  <Table.Head.Cell columnWidth="60%">Name</Table.Head.Cell>
                  <Table.Head.Cell>Amount</Table.Head.Cell>
                </Table.Head.Row>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Form.TextField
                      {...register("name", {
                        validate: validateName,
                      })}
                      placeholder={
                        hidePlaceholders ? "" : "e.g. Pay early discount"
                      }
                      hasErrors={Boolean(errors.name)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex flex-row gap-4">
                      <div className="w-4/6">
                        <AmountTypeSelector
                          id="type-selector"
                          registeredField={register("amountType", {
                            onChange: () => {
                              if (errors.amount) {
                                clearErrors("amount")
                              }
                            },
                          })}
                        />
                      </div>
                      <AmountInputField
                        id="amount-field"
                        registeredField={register("amount", {
                          validate: validateDiscountAmount(selectedAmountType),
                        })}
                        amountType={selectedAmountType}
                        hasErrors={Boolean(errors.amount)}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            {billingCycle === "month_to_month"
              ? renderMonthToMonthOptionFields()
              : null}
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {Object.values(errors).map((error) => {
          return (
            <div key={error.message} className="flex justify-end">
              <Form.Error>{error.message}</Form.Error>
            </div>
          )
        })}
        <div className="mt-2 flex flex-row justify-end gap-4">
          <Button variant="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit(onAddClick)}>
            {selectedDiscount ? "Save" : "Add"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

DiscountsModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  hidePlaceholders: PropTypes.bool,
  selectedDiscount: PropTypes.shape({
    name: PropTypes.string.isRequired,
    amountType: PropTypes.oneOf(["percent", "dollars"]).isRequired,
    amount: PropTypes.string.isRequired,
    discountType: PropTypes.string,
    discountMonth: PropTypes.string,
    discountStartMonth: PropTypes.string,
    discountEndMonth: PropTypes.string,
  }),
  billingCycle: PropTypes.string,
  contractStartDate: PropTypes.instanceOf(Date),
  contractCompleted: PropTypes.bool,
}

export default DiscountsModal
