import Decimal from "decimal.js"

import { safePercentageDecimal } from "../../../utils/number_helpers"
import { camelCaseToSnakecase } from "../../../utils/string_helpers"
import { centsToDollars, dollarsToCents } from "../../Billing/Items/helpers"
import { parseDateValue } from "../../Billing/helpers"

const baseDueOnSignatureInstallment = {
  dueOnSignature: false,
  amountType: "percent",
  amount: 100,
  dueDate: null,
}
const defaultValues = {
  billingCycle: "installments",
  selectedCustomFields: [],
  namedRateId: "custom",
  contractInstallments: [baseDueOnSignatureInstallment],
  installmentDistributionType: "even",
  feesAndDiscounts: [],
  initialDiscountDistributionOption: "evenly",
  rate: {
    pricingStructure: "",
    taxRate: 0,
    longTermStorageProductId: "",
  },
  allowDirectPayment: false,
  acceptedPaymentMethods: [],
  selectedTemplateId: null,
}

const posItemsDataNumericFields = [
  "originalAmount",
  "taxAmount",
  "taxRate",
  "quantity",
  "pricePerUnit",
  "amount",
  "dueDay",
]

export const getInitialValues = (
  form,
  attachments,
  contact,
  contactBoat,
  isGroup,
  selectedTemplateId,
  withTemplate
) => {
  if (!form) {
    return {
      ...defaultValues,
      attachments: attachments || [],
      isGroup,
      withTemplate,
    }
  }

  const initialContact = contact
    ? {
        contact,
        contact_id: contact.id,
        contact_boat: contactBoat,
        contact_boat_id: contactBoat.id,
      }
    : {}

  const { contractInstallments, installmentDistributionType, ...rate } =
    form.rate
  return {
    ...form,
    rate: {
      ...rate,
      amount: centsToDollars(rate.amount),
      taxRate: safePercentageDecimal(rate.taxRate),
      dueDay: rate.dueDay || 1,
    },
    startDate: parseDateValue(form.startDate),
    endDate: form.endDate ? parseDateValue(form.endDate) : null,
    billingCycle: form.billingCycle,
    customBillingPeriod: !!(form.billingStartDate || form.billingEndDate),
    attachments: attachments || [],
    selectedCustomFields: form.contractSigningEnablements || [],
    namedRateId: form.namedRateId || "custom",
    ...initialContact,
    isGroup,
    contractInstallments:
      contractInstallments && contractInstallments.length
        ? contractInstallments.map(
            ({ amountInCents, dueDate, dueOnSignature, percentAmount }) => {
              const amountType = amountInCents ? "dollars" : "percent"
              return {
                dueOnSignature,
                amountType,
                dueDate: dueDate ? parseDateValue(dueDate) : null,
                amount:
                  amountType === "dollars"
                    ? centsToDollars(Number(amountInCents))
                    : Number(percentAmount),
              }
            }
          )
        : [baseDueOnSignatureInstallment],
    installmentDistributionType: installmentDistributionType || "custom",
    posItemsData: form.posItemsData
      ? form.posItemsData.map((item) => {
          const keys = Object.keys(item)
          return keys.reduce((formattedItem, key) => {
            const value = item[key]
            return {
              ...formattedItem,
              [key]:
                posItemsDataNumericFields.includes(key) && value
                  ? Number(value)
                  : value,
            }
          }, {})
        })
      : [],
    allowDirectPayment: form.allowDirectPayment ?? false,
    acceptedPaymentMethods: form.acceptedPaymentMethods ?? [],
    feesAndDiscounts: form.feesAndDiscounts ?? [],
    initialDiscountDistributionOption:
      form.initialDiscountDistributionOption ?? "evenly",
    selectedTemplateId,
    withTemplate,
    applyToQuotes: "false",
  }
}

export const formatContractInstallments = (
  installments,
  isEvenDistribution
) => {
  return installments.map(({ dueOnSignature, dueDate, amountType, amount }) => {
    const isEvenPercent = amountType === "percent" && isEvenDistribution
    const parsedInstallmentAmount = isEvenPercent ? null : Number(amount)
    return {
      amount_type: amountType,
      due_on_signature: dueOnSignature,
      amount: parsedInstallmentAmount,
      due_date: dueOnSignature ? null : dueDate,
    }
  })
}

const formatPosItemsData = (items) => {
  return items.map((item) => {
    return Object.fromEntries(
      Object.entries(item).map(([key, val]) => {
        let value = val
        if (posItemsDataNumericFields.includes(key) && val) {
          value = Number(val)
        }
        return [camelCaseToSnakecase(key), value]
      })
    )
  })
}

export const formatFeesAndDiscountsData = (feesAndDiscounts) => {
  return feesAndDiscounts.map(
    ({
      id,
      name,
      percentage,
      amount,
      discountType,
      discountMonth,
      discountStartMonth,
      discountEndMonth,
    }) => {
      return {
        id,
        category: "discount",
        name,
        percentage: percentage ? Number(percentage) : null,
        amount: amount ? Number(amount) : null,
        discount_type: discountType,
        discount_month: discountMonth,
        discount_start_month: discountStartMonth,
        discount_end_month: discountEndMonth,
      }
    }
  )
}

export const setSubmitParams = (form) => {
  const isMonthToMonth = form.billingCycle === "month_to_month"
  const installments = isMonthToMonth
    ? []
    : formatContractInstallments(
        form.contractInstallments,
        form.installmentDistributionType === "even"
      )
  const feesAndDiscounts = form.feesAndDiscounts ?? []
  return {
    id: form.id,
    start_date: form.startDate,
    amount: dollarsToCents(form.rate.amount),
    tax_rate: form.rate.taxRate
      ? new Decimal(form.rate.taxRate).div(100).toNumber()
      : null,
    rate_id: form.rate.id || null,
    pricing_structure: form.rate.pricingStructure,
    billing_cycle: form.billingCycle,
    notes: form.notes,
    attachments: form.attachments ? form.attachments.map(({ id }) => id) : null,
    insurance_requirement: form.insuranceRequirement,
    registration_requirement: form.registrationRequirement,
    pos_items_data: form.posItemsData
      ? JSON.stringify(formatPosItemsData(form.posItemsData))
      : null,
    named_rate_id: form.namedRateId === "custom" ? "" : form.namedRateId,
    month_to_month: isMonthToMonth,
    contract_signing_enablements: form.selectedCustomFields
      ? JSON.stringify(
          form.selectedCustomFields.map(
            ({ customFieldDefinitionId, required }) => {
              return {
                custom_field_definition_id: customFieldDefinitionId,
                required,
              }
            }
          )
        )
      : null,
    long_term_storage_product_id: form.rate.longTermStorageProductId,
    allow_direct_payment: form.allowDirectPayment,
    due_day: isMonthToMonth ? form.rate.dueDay : null,
    end_date: isMonthToMonth ? null : form.endDate,
    contract_installments: JSON.stringify(installments),
    accepted_payment_methods:
      form.acceptedPaymentMethods && form.acceptedPaymentMethods.length
        ? JSON.stringify(form.acceptedPaymentMethods)
        : null,
    fees_and_discounts:
      feesAndDiscounts.length > 0
        ? JSON.stringify(formatFeesAndDiscountsData(feesAndDiscounts))
        : null,
    initial_discount_distribution_option:
      form.billingCycle === "installments" && feesAndDiscounts.length > 0
        ? form.initialDiscountDistributionOption
        : null,
    docuseal_template_id: form.selectedTemplateId,
    pending: true,
    with_template: form.withTemplate,
    apply_to_quotes: form.applyToQuotes,
  }
}
